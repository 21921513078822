import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

export default function carta(props) {
  return (
    <Card
      style={{ width: "15rem" }}
      border="primary"
      text="black"
    >
      <Card.Img variant="top" src={props.url} />
      <Card.Body>
        <Card.Title>ITSPRO</Card.Title>
      </Card.Body>
    </Card>
  );
}
