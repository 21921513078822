import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "../components/card";
import Badge from "react-bootstrap/Badge";

const imagenes = [
  "https://i.imgur.com/KQAS8Hg.jpeg",
  "https://i.imgur.com/2s9FhyP.png",
  "https://i.imgur.com/o8EhoQT.jpeg",
  "https://i.imgur.com/QxNxT6C.jpeg",
  "https://i.imgur.com/YBqAGmt.jpeg",
  "https://i.imgur.com/uqxu4nD.jpeg",
  "https://i.imgur.com/oi8Zc3O.jpeg",
];

function rendercards() {
  let ar = [];
  for (let i = 0; i < imagenes.length; i++) {
    ar.push(
      <div key={i} align="center">
        <Card url={imagenes[i]} />
        <br />
        <br />
      </div>
    );
  }
  return ar;
}

export default function Portafolio() {
  return (
    <Container>
      <br />
      <h1 align="center">
        <Badge variant="outline-secondary" style={{ color: "white" }}>
          PORTAFOLIO
        </Badge>
      </h1>
      <br />
      <br />
      <Row>
        <Col>{rendercards()}</Col>
      </Row>
    </Container>
  );
}
