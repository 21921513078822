import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

export default function Contacto(props) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center" /* horizontally center */,
        alignItems: "center" /* vertically center */,
        height: "100vh",
      }}
    >
      <Card
        className="text-center"
        style={{ width: "25rem", margin: "auto", padding: "20px" }}
      >
        <Card.Header style={{ backgroundColor: "#007bff", color: "#fff" }}>
          CONTACTO
        </Card.Header>
        <Card.Body>
          <Card.Title>ITSPRO Central America SA</Card.Title>
          <Card.Text>
            <b>Gerente General:</b> Edgar Dieguez <br />
            <b>Teléfono:</b> +502 57093134 <br />
            <b>Correo Electrónico:</b> edieguez@outlook.es
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
