module.exports = {
  quienes_somos: {
    titulo: "Quienes Somos",
    parrafo: `ITSpro es una empresa con amplia experiencia, somos expertos en diseño, ejecución y
mantenimiento de infraestructuras de cableado estructurado de acuerdo a los
estándares
y normativas internacionales ANSI/EIA/TIA/ISO, contamos con mano de obra
profesional
respaldada por técnicos con 23 años de experiencia.
`


  },
  nuestra_mision: {
    titulo: "Areas de Experiencia",
    parrafo:
      `- Diseño, instalación y certificación de cableado estructurado categorías 5e, 6, 6ª, 7ª y
8.\n
- Diseño, instalación y certificación de fibra óptica multimodal y monomodo\n
- Diseño y instalación de sistemas de redes inalámbricas\n
- Fusión de fibra óptica, certificación nivel 1 y 2.\n
- Barreras de Acceso Vehicular\n
- Equipos activos, switchs, enrutadores, puntos de acceso inalámbrico.\n
- Mantenimiento y soporte técnico\n
- Certificación de redes de cobre\n
- Sistemas de video vigilancia\n
- Control de acceso\n
- Sistemas multimedia\n
- Consultoría y auditoría de instalaciones\n
- Limpieza y ordenamiento de centros de cablead\n
- Análisis y resolución de fallas de red\n
- Cableado eléctrico: plantas de emergencia, ups, acometidas, redes de energía\n
normal, regulada, iluminación, tierras físicas.`,
  },
  nuestra_vision: {
    titulo: "Nuestra Vision",
    parrafo:
      "Este es un parrafo de prueba. Para cambiar el textp de este parrafo editar el archivo informacionInicio.json en src/data/informacionInicio.json",
  },
  nuestros_valores: {
    titulo: "Nuestros Valores",
    parrafo:
      "Este es un parrafo de prueba. Para cambiar el textp de este parrafo editar el archivo informacionInicio.json en src/data/informacionInicio.json",
  },
};
