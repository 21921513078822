import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";

export default function Menu(props) {
  return (
    <Navbar>
      <Navbar.Brand>
        <Link to="/inicio">
          <img
            src={require("../data/logo.png")}
            alt="ITSPRO"
            style={{ width: "150px", height: "55px" }}
          />
        </Link>
      </Navbar.Brand>
      <Nav className="mr-auto">
        <Nav.Link>
          {" "}
          <Link to="/portafolio" style={{ color: "#315094" }}>
            Portafolio
          </Link>{" "}
        </Nav.Link>
        <Nav.Link>
          <Link to="/contacto" style={{ color: "#315094" }}>
            Contacto
          </Link>
        </Nav.Link>
      </Nav>
      <style jsx>{`
        .navbar {
          background-color: white; /* Set background to white */
        }
      `}</style>
    </Navbar>
  );
}
