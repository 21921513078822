import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import informacionInicio from "../data/informacionInicio";
import Jumbotron from "react-bootstrap/Jumbotron";

export default function Inicio() {
  return (
    <Container>
      <br />
      <br />
      <Row>
        <Col>
          <Jumbotron
            titulo="Quienes Somos"
            style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.5)" }}
          >
            <h2 style={{ fontWeight: "bold" }}>Quienes Somos</h2>
            <b>ITSpro</b> es una empresa con amplia experiencia, somos expertos
            en diseño, ejecución y mantenimiento de infraestructuras de cableado
            estructurado de acuerdo a los estándares y normativas
            internacionales <b>ANSI</b>/<b>EIA</b>/<b>TIA</b>/<b>ISO</b>,
            contamos con mano de obra profesional respaldada por técnicos con 23
            años de experiencia.
          </Jumbotron>

          <Jumbotron
            style={{
              backgroundColor: "#2c2d2e",
              color: "white",
              boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.5)", // Adjust shadow as needed
            }}
          >
            <h2 style={{ fontWeight: "bold" }}>Nuestros Clientes</h2>
            <div className="client-list">
              <div className="row">
                <div className="col-md-6">
                  <ul>
                    <li>TIGO Madero Roosevelt</li>
                    <li>NINOSHKA</li>
                    <li>MEGAPACA Bodegas centrales Palin</li>
                    <li>
                      POLIWATT oficinas Edificio Reforma 10, planta PQP puerto
                      Quetzal
                    </li>
                    <li>Plan Internacional edificio Desing Center</li>
                    {/* Add more clients as needed */}
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul>
                    <li>Municipalidad de Guatemala</li>
                    <li>BIC de Guatemala</li>
                    <li>Hospital San Juan de Dios</li>
                    <li>IGSS División de Mantenimiento (6A)</li>
                    <li>
                      INACIF sedes de Chisec, Morales, San Juan Sacatepequez,
                      Villa Canales, Rabinal, San jose Pïnula
                    </li>
                    {/* Add more clients as needed */}
                  </ul>
                </div>
              </div>
            </div>
          </Jumbotron>

          <Jumbotron
            style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.5)" }}
          >
            <h2 style={{ fontWeight: "bold" }}>Areas de Experiencia</h2>
            <ul>
              <li>
                Diseño, instalación y certificación de cableado estructurado
                categorías 5e, 6, 6ª, 7ª y 8ª.
              </li>
              <li>
                Diseño, instalación y certificación de fibra óptica multimodal y
                monomodo.
              </li>
              <li>Diseño y instalación de sistemas de redes inalámbricas.</li>
              <li>Fusión de fibra óptica, certificación nivel 1 y 2.</li>
              <li>Barreras de Acceso Vehicular.</li>
              <li>
                Equipos activos, switchs, enrutadores, puntos de acceso
                inalámbrico.
              </li>
              <li>Mantenimiento y soporte técnico.</li>
              <li>Certificación de redes de cobre.</li>
              <li>Sistemas de video vigilancia.</li>
              <li>Control de acceso.</li>
              <li>Sistemas multimedia.</li>
              <li>Consultoría y auditoría de instalaciones.</li>
              <li>Limpieza y ordenamiento de centros de cableado.</li>
              <li>Análisis y resolución de fallas de red.</li>
              <li>
                Cableado eléctrico: plantas de emergencia, ups, acometidas,
                redes de energía normal, regulada, iluminación, tierras físicas.
              </li>
            </ul>
          </Jumbotron>
        </Col>
      </Row>
    </Container>
  );
}
